import { useEffect, useState } from "react";
import axios from "axios";

import { Reward } from "util/typesAlt";

const getReward = (datum: any): Reward => {
  const parsed = parseFloat(datum.points);
  const reward: Reward = {
    exclude: datum.exclude === "y",
    createdDatetime: datum.created_datetime,
    title: datum.title,
    description: datum.description,
    points: isNaN(parsed) ? undefined : parsed,
    redeemedDatetime: datum.redeemed_datetime,
  };

  return reward;
};

export const useRewards = (): {
  rewards: Reward[] | null;
  fetchRewards: () => Promise<void>;
} => {
  const [rewards, setRewards] = useState<Reward[] | null>(null);

  const fetchRewards = async () => {
    let url = `${process.env.REACT_APP_BEHAVE_API_URL}/rewards`;

    try {
      const body = await axios.get(url);
      let data = body.data?.rewards;
      if (data === undefined) {
        throw new Error("body does not contain rewards data");
      }

      let rewards: Reward[] = data.map(getReward);

      // Exclude
      rewards = rewards.filter((reward) => !reward.exclude);

      setRewards(rewards);
    } catch (error) {
      console.error("failed to update rewards", error);
      setRewards(null);
    }
  };

  // Make an initial call
  useEffect(() => {
    fetchRewards();
  }, []);

  return { rewards, fetchRewards };
};
