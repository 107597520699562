import { Color } from "util/typesAlt";

export const formatDatetimeString = (
  datetimeString?: string,
  showWeekday: boolean = false
): string => {
  if (datetimeString === undefined || datetimeString === "") {
    return "";
  }
  const date = new Date(datetimeString);

  const now = new Date();
  const showYear = date.getFullYear() !== now.getFullYear();

  return date.toLocaleDateString("en-US", {
    year: showYear ? "2-digit" : undefined,
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    weekday: showWeekday ? "short" : undefined,
  });
};

const colorValues: { [key in Color]: string } = {
  yellow: "yellow-500",
  green: "green-500",
};

export const getColorValue = (color: Color | undefined): string => {
  if (color !== undefined && colorValues[color] !== undefined) {
    return colorValues[color];
  }
  return "gray-500";
};

export const makeCopy = <T>(value: T): T => {
  if (typeof value === "object" && value !== null && !Array.isArray(value)) {
    let newObject: any = {};
    for (let prop in value) {
      newObject[prop] = makeCopy(value[prop]);
    }
    return newObject as T;
  } else if (Array.isArray(value)) {
    let newArray: any[] = [];
    for (let element of value) {
      newArray.push(makeCopy(element));
    }
    return newArray as T;
  }
  return value;
};

export const emptyToUndefined = (
  value: string | number | boolean | undefined
): string | number | boolean | undefined => {
  if (typeof value === "number" && isNaN(value)) {
    return undefined;
  }
  if (typeof value === "string" && value.length === 0) {
    return undefined;
  }
  return value;
};

export const formatDateIso = (date: Date): string => {
  const localTime = date.toLocaleString("en-us", {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  });
  const localDate = date.toLocaleString(["id"], {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  const [day, month, year] = localDate.split("/");
  return `${year}-${month}-${day}T${localTime}`;
};

export const isFalsy = (value: any): boolean => {
  if (value === "" || value === undefined || value === null) {
    return true;
  }
  if (Array.isArray(value)) {
    let arrayIsFalsy = true;
    for (let item of value) {
      if (!isFalsy(item)) {
        arrayIsFalsy = false;
      }
    }
    return arrayIsFalsy;
  }
  if (typeof value === "object") {
    let objectIsFalsy = true;
    for (let prop in value) {
      if (!isFalsy(value[prop])) {
        objectIsFalsy = false;
      }
    }
    return objectIsFalsy;
  }
  return false;
};

// Generates a 6 char mostly-random 0-z string
export const generateId = () => Math.random().toString(36).slice(2, 8);
