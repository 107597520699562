import { useEffect, useState } from "react";
import axios from "axios";

import { Reward, Task } from "util/typesAlt";

export const derivePoints = (
  tasks: Task[] | null,
  rewards: Reward[] | null
): number | null => {
  if (tasks === null || rewards === null) {
    return null;
  }
  const completedTasks = tasks.filter(
    (task) => !task.about?.exclude && task.feedback?.points !== undefined
  );
  const redeemedRewards = rewards.filter(
    (reward) =>
      !reward.exclude &&
      reward.redeemedDatetime !== undefined &&
      reward.redeemedDatetime.length > 0
  );

  const pointsEarned = completedTasks
    .map((task) =>
      task.feedback?.points === undefined ? 0 : task.feedback.points
    )
    .reduce((soFar, current) => soFar + current, 0);

  const pointsSpent = redeemedRewards
    .map((reward) => (reward.points === undefined ? 0 : reward.points))
    .reduce((soFar, current) => soFar + current, 0);

  return pointsEarned - pointsSpent;
};

export const usePoints = (): {
  points: number | null;
  fetchPoints: () => Promise<void>;
} => {
  const [points, setPoints] = useState<null | null>(null);

  const fetchPoints = async () => {
    let url = `${process.env.REACT_APP_BEHAVE_API_URL}/points`;

    try {
      const body = await axios.get(url);
      const points = body.data?.points;
      if (points === undefined) {
        throw new Error("body does not contain points data");
      }

      setPoints(points);
    } catch (error) {
      console.error("failed to update points", error);
      setPoints(null);
    }
  };

  // Make an initial call
  useEffect(() => {
    fetchPoints();
  }, []);

  return { points, fetchPoints };
};
