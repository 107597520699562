import { useEffect, useState } from "react";

import ProgressChart from "./ProgressChart";
import { Task, Reward } from "util/typesAlt";
import TaskCard from "components/common/TaskCard";
import { isFalsy } from "util/util";

const isDraft = (task: Task): boolean => {
  return task.feedback === undefined || isFalsy(task.feedback);
};

const listAfter = (a: Task, b: Task): number => {
  if (a.feedback?.datetime === undefined) {
    return 1;
  }
  if (b.feedback?.datetime === undefined) {
    return -1;
  }
  return a.feedback.datetime < b.feedback.datetime ? 1 : -1;
};

const HomePage = ({
  rewards,
  tasks,
  points,
  disableRedact = false,
}: {
  rewards: Reward[] | null;
  tasks: Task[] | null;
  points: number | null;
  disableRedact?: boolean;
}) => {
  const [rewardIndex, setRewardIndex] = useState<number | null>(null);

  const selectedReward =
    rewards !== null && rewardIndex !== null ? rewards[rewardIndex] : null;

  const setRewardIndexSynced = (newIndex: number) => {
    setRewardIndex(newIndex);
    localStorage.setItem("rewardIndex", newIndex.toString());
  };

  useEffect(() => {
    if (rewards === null) {
      setRewardIndex(null);
      return;
    }
    if (rewards.length > 0) {
      let savedIndex = localStorage.getItem("rewardIndex");
      if (savedIndex !== null && parseInt(savedIndex) < rewards.length) {
        setRewardIndex(parseInt(savedIndex));
        return;
      }
      setRewardIndexSynced(rewards.length - 1);
    }
  }, [rewards]);

  return (
    <div>
      <div className="flex pt-6 px-3">
        <div className="flex-1">
          {points !== null && selectedReward?.points !== undefined && (
            <ProgressChart progress={points} goal={selectedReward.points} />
          )}
        </div>
        {rewards !== null && (
          <div className="flex-1 border border-gray-700 rounded pt-2">
            {rewards.map((reward, i) => (
              <div
                key={i}
                className={`px-2 select-none ${
                  rewardIndex === i
                    ? "bg-blue-500 text-gray-950"
                    : "text-gray-600"
                }`}
                onClick={() => setRewardIndexSynced(i)}
              >
                {reward.title}
              </div>
            ))}
          </div>
        )}
      </div>
      <>
        {tasks !== null && (
          <div className="p-3">
            {tasks
              .filter((task) => !task.about?.exclude && !isDraft(task))
              .sort(listAfter)
              .map((task, i) => (
                <div key={i} className="mt-4">
                  <TaskCard key={i} task={task} disableRedact={disableRedact} />
                </div>
              ))}
          </div>
        )}
      </>
    </div>
  );
};

export default HomePage;
