import { TaskAbout } from "util/typesAlt";
import { emptyToUndefined } from "util/util";

const TaskAboutForm = ({
  about,
  handleAboutChange,
}: {
  about: TaskAbout;
  handleAboutChange: (about: TaskAbout) => void;
}) => {
  const handleInputChange = (name: string, value: string | boolean) => {
    handleAboutChange({ ...about, [name]: emptyToUndefined(value) });
  };

  return (
    <form className="text-gray-500">
      <div>
        <label>
          Exclude
          <input
            className="bg-gray-800 rounded border border-gray-600 ml-1"
            type="checkbox"
            checked={about.exclude || false}
            onChange={(event) =>
              handleInputChange("exclude", event.target.checked)
            }
          />
        </label>
      </div>
      <div className="mt-3">
        <label>
          Redact
          <input
            className="bg-gray-800 rounded border border-gray-600 ml-1"
            type="checkbox"
            checked={about.redact || false}
            onChange={(event) =>
              handleInputChange("redact", event.target.checked)
            }
          />
        </label>
      </div>
      <div className="mt-3">
        <label>
          Color
          <select
            className="bg-gray-800 rounded py-0 px-1 ml-1 text-gray-200"
            value={about.color || ""}
            onChange={(event) => handleInputChange("color", event.target.value)}
          >
            <option value="">None</option>
            <option value="yellow">Yellow</option>
            <option value="green">Green</option>
          </select>
        </label>
      </div>
      <div className="mt-3">
        <label>
          Title
          <input
            className="bg-gray-800 rounded py-0 px-1 text-gray-200 w-full"
            type="text"
            value={about.title || ""}
            onChange={(event) => handleInputChange("title", event.target.value)}
          />
        </label>
      </div>
      <div className="mt-3">
        <label>
          Description
          <textarea
            className="bg-gray-800 rounded py-0 px-1 text-gray-200 w-full"
            value={about.description || ""}
            rows={3}
            onChange={(event) =>
              handleInputChange("description", event.target.value)
            }
          />
        </label>
      </div>
    </form>
  );
};

export default TaskAboutForm;
