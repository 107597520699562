import { useEffect, useState } from "react";
import axios from "axios";

import { Task } from "util/typesAlt";

const getTask = (datum: any): Task => {
  const parsed = parseFloat(datum.feedback_points);
  const task: Task = {
    id: datum.id,
    createdDatetime: datum.created_datetime,
    about: {
      exclude: datum.exclude === "y",
      color: datum.color,
      title: datum.title,
      description: datum.description,
      redact: datum.redact === "y",
    },
    promptResponse: {
      initiative: datum.initiative === "y",
      context: datum.prompt_response_context,
    },
    response: {
      datetime: datum.task_response_datetime,
      text: datum.task_response_text,
      mediaDescription: datum.task_response_media_description,
    },
    feedback: {
      datetime: datum.feedback_datetime,
      text: datum.feedback_text,
      achievements: datum.feedback_achievements,
      points: isNaN(parsed) ? undefined : parsed,
    },
  };

  return task;
};

export const useTasks = (): {
  tasks: Task[] | null;
  fetchTasks: () => Promise<void>;
} => {
  const [tasks, setTasks] = useState<Task[] | null>(null);

  const fetchTasks = async () => {
    let url = `${process.env.REACT_APP_BEHAVE_API_URL}/tasks`;

    try {
      const body = await axios.get(url);
      let data = body.data?.tasks;
      if (data === undefined) {
        throw new Error("body does not contain tasks data");
      }

      let tasks: Task[] = data.map(getTask);

      setTasks(tasks);
    } catch (error) {
      console.error("failed to update tasks", error);
      setTasks(null);
    }
  };

  // Make an initial call
  useEffect(() => {
    fetchTasks();
  }, []);

  return {
    tasks,
    fetchTasks,
  };
};
